<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "일상점검 결과 목록"
            :merge="grid.merge"
            :columns="grid.columns"
            :filtering="false"
            :data="grid.data"
            :gridHeight="grid.height"
            :selection="data.dailyCheckId ? 'multiple': 'none'"
            rowKey="equipmentCd"
            :editable="!disabled"
            :columnSetting="false"
            :isExcelDown="false"
            :usePaging="false"
            @headLinkClick="headLinkClick"
          >
            <template slot="prefixContent">
              <font v-if="!data.lineId && !data.checkDate" color="#C10015">
                ※ 기능위치와 점검년월을 선택하세요.
              </font>
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/> : 정상 &nbsp;&nbsp;
                <q-icon name="close" class="text-black"/> : 이상 &nbsp;&nbsp;
                <!-- <q-icon name="circle" class="text-black"/> : 청소 &nbsp;&nbsp;
                V : 교환 &nbsp;&nbsp;
                <q-icon name="check_box_outline_blank" class="text-black"/> : 마모 &nbsp;&nbsp;
                △ : Oil 누유 &nbsp;&nbsp;
                ▲ : 점검필요 &nbsp;&nbsp; -->
                <!-- <q-icon name="warning_amber" class="text-black"/> : Oil 누유 &nbsp;&nbsp;
                <q-icon name="warning" class="text-black"/> : 점검필요 &nbsp;&nbsp; -->
                <!-- <q-icon name="radio_button_checked" class="text-black"/> : 비가동 &nbsp;&nbsp; -->
              </font>
            </template> 
            <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:700;">
                <q-chip color="green" />
                   {{ '개선 건 없음' }}
                <q-chip color="red" />
                   {{ '개선 1건 이상' }}
                {{' [row 클릭 시 개선요청 가능]'}}
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'cycle1'">
                <span v-if="props.row['cycle1'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle2'">
                <span v-if="props.row['cycle2'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cbmTypeName'">
                <span v-if="props.row['cbmFlag'] === 'Y'">
                  {{props.row['cbmTypeName']}}
                </span>
                <q-btn
                v-if="props.row['cbmFlag'] === 'Y'"
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="inner-help-btn"
                  align="center"
                  round>
                  <q-tooltip>
                    <div class="tooltip-layer">
                    <div class="tooltip-date">경고값/실행값(XX ~ XX)</div><br/>
                      <div class="tooltip-title">경고값</div><div class="tooltip-content">{{ props.row.gaugeWarnLcl}} ~ {{ props.row.gaugeWarnUcl}}</div><br/>
                      <div class="tooltip-title">실행값</div><div class="tooltip-content">{{ props.row.gaugeExecLcl}} ~ {{ props.row.gaugeExecUcl}}</div><br/>
                    </div>
                  </q-tooltip>
                </q-btn>
              </template>
              <template v-else-if="col.name === 'cycle3'">
                <span v-if="props.row['cycle3'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else>
                <template v-if="props.row[col.name]">
                  <font 
                    style="font-size:0.9em;font-weight:700;" 
                    :color="customColor(props.row, col)"
                    class="cursor-pointer" 
                    @click="openPop(col, props.row)"
                  >
                    {{props.row[col.name]}}
                  </font>
                </template>
              </template>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions" :returnData="{ col1: data.dailyCheckId }"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        dailyImproveModelList: [], 
      }),
    },
    grid: {
      merge: [
        { index: 0, colName: 'equipmentCd' },
      ],
      height: 'auto',
      columns: [],
      data: [],
    },
  },
  data() {
    return {
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.checkStatusCd === 'MCSC000015' || (this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.plan.daily.update.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.daily.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.daily.complete.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      let selectData = this.$refs['table'].selected;
      let realData = [];
      this.$_.forEach(selectData, _item => {
        this.$_.forEach(this.$_.filter(this.grid.data, {equipmentCd : _item.equipmentCd}), __item => {
          if (this.$_.findIndex(realData, {dailyCheckResultId: __item.dailyCheckResultId}) === -1) {
            realData.push(__item)
          } 
        })
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검할 설비를 체크하세요.', // 점검할 설비/장비를 체크하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.popupOptions.param = {
          col: col,
          equipmentList: realData,
          data: this.data,
        };
        this.popupOptions.title = '일상점검 결과 상세'; 
        this.popupOptions.target = () =>
          import(`${"./preventiveCheckTab01Pop.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '95%';
        this.popupOptions.closeCallback = this.closeResultPopup;
      }
    },
    closeResultPopup(returnData) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (returnData.col1) {
        this.data.dailyCheckId = returnData.col1;
        this.$emit('setKey', returnData.col1)
      } else {
        this.$emit('getDetail')
      }
    },
    openPop(col, row) {
      this.popupOptions.param = {
        dailyCheckId: this.data.dailyCheckId,
        dailyCheckResultId: row.dailyCheckResultId,
        checkItemName: row.equipmentName + '/' + row.checkItemPartName + '/' + row.checkItemName,
        key: col.key,
        checkStatusCd: this.data.checkStatusCd,
        sysApprovalRequestId: this.data.sysApprovalRequestId,
        approvalStatusCd: this.data.approvalStatusCd,
      };
      this.popupOptions.title = col.date + ' 개선 상세'; 
      this.popupOptions.target = () =>
        import(`${"./preventiveCheckImprPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    datachange2(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    setColors(row, col) {
      if (Number(row.gaugeExecLcl) >= Number(row[col.key]) || Number(row.gaugeExecUcl) <= Number(row[col.key])) {
        return 'red'
      } else if (Number(row.gaugeWarnLcl) >= Number(row[col.key]) || Number(row.gaugeWarnUcl) <= Number(row[col.key])) {
        return 'orange'
      } else {
        return 'black'
      }
    },
    customColor(row, col) {
      let tableKey = this.$_.split(row['tableKeys'], ',');
      let color = 'green';
      if (tableKey && tableKey.length > 0) {
        this.$_.forEach(tableKey, item => {
          if (item === col.key) {
            color = 'red'
          } 
        })
      }
      return color;
    },
  }
};
</script>
<style lang="scss">
.tooltip-title {
  width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  border-radius: 5px;
  background: #757474;
  color: #fff;
  margin: 2px;
}
.tooltip-layer {
  background-color: #fff;
  color: #504f4f;
  padding: 5px;
}
.tooltip-content {
  padding-left: 5px;
  display: inline-block;
  line-height: 24px;
  font-weight: 600;
}
.tooltip-date {
  width: 100%;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1em;
}
</style>
