var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "table",
                    attrs: {
                      title: "일상점검 결과 목록",
                      merge: _vm.grid.merge,
                      columns: _vm.grid.columns,
                      filtering: false,
                      data: _vm.grid.data,
                      gridHeight: _vm.grid.height,
                      selection: _vm.data.dailyCheckId ? "multiple" : "none",
                      rowKey: "equipmentCd",
                      editable: !_vm.disabled,
                      columnSetting: false,
                      isExcelDown: false,
                      usePaging: false,
                    },
                    on: { headLinkClick: _vm.headLinkClick },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "cycle1"
                              ? [
                                  props.row["cycle1"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : col.name === "cycle2"
                              ? [
                                  props.row["cycle2"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : col.name === "cbmTypeName"
                              ? [
                                  props.row["cbmFlag"] === "Y"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row["cbmTypeName"]) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  props.row["cbmFlag"] === "Y"
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "inner-help-btn",
                                          attrs: {
                                            icon: "help",
                                            color: "deep-purple-6",
                                            "text-color": "white",
                                            align: "center",
                                            round: "",
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _c(
                                              "div",
                                              { staticClass: "tooltip-layer" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "tooltip-date",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "경고값/실행값(XX ~ XX)"
                                                    ),
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-title",
                                                  },
                                                  [_vm._v("경고값")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.row.gaugeWarnLcl
                                                      ) +
                                                        " ~ " +
                                                        _vm._s(
                                                          props.row.gaugeWarnUcl
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-title",
                                                  },
                                                  [_vm._v("실행값")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.row.gaugeExecLcl
                                                      ) +
                                                        " ~ " +
                                                        _vm._s(
                                                          props.row.gaugeExecUcl
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _c("br"),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : col.name === "cycle3"
                              ? [
                                  props.row["cycle3"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : [
                                  props.row[col.name]
                                    ? [
                                        _c(
                                          "font",
                                          {
                                            staticClass: "cursor-pointer",
                                            staticStyle: {
                                              "font-size": "0.9em",
                                              "font-weight": "700",
                                            },
                                            attrs: {
                                              color: _vm.customColor(
                                                props.row,
                                                col
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openPop(
                                                  col,
                                                  props.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row[col.name]) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "prefixContent" },
                      [
                        !_vm.data.lineId && !_vm.data.checkDate
                          ? _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(" ※ 기능위치와 점검년월을 선택하세요. "),
                            ])
                          : _vm._e(),
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "radio_button_unchecked" },
                            }),
                            _vm._v(" : 정상    "),
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "close" },
                            }),
                            _vm._v(" : 이상    "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "suffixTitle" },
                      [
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "700",
                            },
                          },
                          [
                            _c("q-chip", { attrs: { color: "green" } }),
                            _vm._v(" " + _vm._s("개선 건 없음") + " "),
                            _c("q-chip", { attrs: { color: "red" } }),
                            _vm._v(
                              " " +
                                _vm._s("개선 1건 이상") +
                                " " +
                                _vm._s(" [row 클릭 시 개선요청 가능]") +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", {
            attrs: {
              param: _vm.popupOptions,
              returnData: { col1: _vm.data.dailyCheckId },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }